import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"

const PersonalTrainingGetStarted = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A confident woman at the top of her push-up position"
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-l">
          <span className="type-emph type-break__after">Personal</span> Training
        </h2>
        <div className="container-type__white">
          <p className="type-reg type-white type-body">
            We provide Personal Training in Omaha to promote a strong and
            healthy lifestyle. It does not matter who you are or where you are
            in your fitness journey. With an individualized program you will
            achieve amazing results:
          </p>
          <ul>
            <li className="type-reg type-white type-body li li-yellow">
              Get stronger and increase your endurance
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              Improve your flexibility
            </li>
            <li className="type-reg type-white type-body li li-yellow">
              Feel more confident
            </li>
          </ul>
          <h3 className="type type-heavy type-yellow type-h3">
            Get Started with a Free Session
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            Begin your Omaha Personal Training Program with a free session.
            During your first workout we will take you through exercises that
            test your skills in strength, endurance, and flexibility. We will
            work together to assess your current fitness level, goals, needs,
            and abilities. After you complete your workout, we will discuss and
            find a plan that fits you perfectly.
          </p>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-1"
            >
              Schedule your Session
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default PersonalTrainingGetStarted
