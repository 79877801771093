import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
const HomeCommunity = (props) => {
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.sectionImage.childImageSharp.fluid}
        className="banner-community u-relative"
      >
        <div className="u-center-text review-container">
          <h2 className="type-header__locations type-emph type-black">
            Fitness <span className="type-heavy">COMMUNITY</span>
          </h2>
        </div>
        <div className="review-position">
          <div className="review-shape">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-yellow review-caption">
                PT
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                Personal Training - Kate Schmitz
              </h3>
              <p className="type-reg type-body review-text__body">
                I have been training for two years and I appreciate the
                dimension it has added to my life. Because the workouts are more
                functional in nature, it transitions well to physical demands
                necessary in daily activities. Training has increased my
                strength, flexibility, and balance.
              </p>
            </div>
          </div>
          <div className="review-shape u-mt-2">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-yellow review-caption">
                PT
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                Personal Training - Brady Gibson
              </h3>
              <p className="type-reg type-body">
                I started coming about two and a half years ago. I love the
                workouts, coaches, and other members. I have never had the same
                workout, so I never feel bored at the gym. I feel a physical and
                mental strength I have never felt before. Thanks FitFarm for the
                amazing adventure.
              </p>
            </div>
          </div>
          <div className="u-center-text u-mt-5">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__black type-heavy type-black"
            >
              Join our Community
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeCommunity
