import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-banner.svg"

const PersonalTrainingCoaches = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-l">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A proud and confident looking man after performing a hang clean"
            className="img-section__container-l__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-right"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-r">
          <span className="type-emph type-white type-break__after">
            FitFarm
          </span>{" "}
          Coaches
        </h2>
        <div className="container-type__black">
          <p className="type-reg type-black type-body">
            Our Omaha Coaches provide you with the most optimal and effective
            way for you to exercise. Our coaches are excited to watch you grow
            during your fitness journey. The benefits of having a personal
            trainer at FitFarm include:
          </p>

          <p className="type-reg type-black type-body">
            <span className="type-heavy type-black">
              Quicker and Maintainable Results:
            </span>{" "}
            Your trainer is responsible for creating a program that is enjoyable
            and easy to follow. You will see faster results because you are
            maximizing your workouts with our trainers. You will maintain your
            progress, because you enjoy our workouts.
          </p>
          <p className="type-reg type-black type-body">
            <span className="type-heavy type-black">No more Plateaus.</span> Our
            trainers can adjust your workouts to avoid fitness plateaus. Seeing
            your results dwindle can lead to a lack motivation. Your coach will
            know when it is a bad day or if it is time to switch up your
            routine.
          </p>
          <p className="type-reg type-black type-body">
            <span className="type-heavy type-black">Healthy Habits.</span> In
            order for you to succeed in your fitness life, you’ll need to learn
            healthy habits. We will teach you why the movements are important
            and how to perform them without injury.
          </p>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black"
            >
              Talk to a Coach
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default PersonalTrainingCoaches
